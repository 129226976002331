@import url(https://fonts.googleapis.com/css2?family=DM+Mono&display=swap);
body {
  margin: 0;
  font-family: 'DM Mono', monospace !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0f0e0e !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.awssld__content { 
  background-color: #ffffff !important;
  overflow-x: scroll !important;
  white-space: nowrap;
  justify-content: end !important;
}



.awssld__bullets {
  display: none !important;
}

.studio__container {
  position: relative;
  width: 70%;
  min-width: 100vh;
}

.awssld__content > div.gallery-page {
  overflow-x: scroll;
  /* position: absolute; */
}

.awssld__content {
  overflow-x: overlay !important;
}

.awssld__content > div {
  width: 100%;
}

div::-webkit-scrollbar {
  -webkit-appearance: none;
}
div::-webkit-scrollbar:horizontal {
  height: 11px;
}
div::-webkit-scrollbar-thumb {
  border: 2px solid white;
  background-color:#cecdcd;
}
div::-webkit-scrollbar-track { 
  background-color: transparent; 
  border-radius: 8px; 
} 
.studio {
  background-color: white;
  height: 100vh;
}
@media all and (orientation: portrait) {
  .studio {
    position: absolute;
    left: 2.5%;
    top: 2.5vw;
  }
  .studio__peeking-mondrian {
    display: none;
  }
  .studio__canvas-options {
    display: none;
  }
  .studio__title {
    display: none;
  }
}

.resizer--hover {
  border: 5px solid rgba(0, 0, 0, 0.5);
  transition: all 2s ease;
}

.resizer {
  transition: all 2s ease;
}

html {
  overflow: hidden;
}

@media all and (orientation: landscape) {
  .studio {
    padding: 10px;
    z-index: 99999999;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .studio-container {
    position: relative;
    width: 75vw;
    min-width: 100vh;
    height: 100vh;
    z-index: 0;
  }
  .studio__canvas-options--mobile {
    display: none;
  }
}

.studio__canvas-options--mobile {
  margin-top: 20px;
  text-align: left;
}

.studio__label {
  width: 100%;
  margin: 0 auto;
  padding-top: 5px;
}

.studio__input {
  border: none !important;
  border-radius: none !important;
  text-align: center !important;
  padding: 0px !important;
  height: 1.5em !important;
}

.form-control:focus {
  box-shadow: none !important;
  text-decoration: underline;
}

.studio__input--artist {
  font-size: 1.3em !important;
}

.studio__input--title {
  font-style: italic !important;
}

.studio__input--twitter {
  font-size: 0.7em !important;
}

.studio__canvas-button {
  margin: 10px 0 !important;
  border: 1px solid #afafaf !important;
  border-radius: 0 !important;
  background-color: #eef0eb !important;
}

.studio__canvas-button--mobile {
  margin: 10px 10px !important;
  border: 1px solid #afafaf !important;
  border-radius: 0 !important;
  background-color: #eef0eb !important;
}

.studio__square-button {
  width: 40px !important;
  height: 40px !important;
}

.studio__portrait-button {
  width: 30px !important;
  height: 40px !important;
  /* margin-left: 5px !important ;  */
}

.studio__landscape-button {
  width: 40px !important;

  height: 30px !important;
}

.studio__canvas-button--active {
  background-color: #afafaf !important;
}

.studio__save {
  cursor: pointer;
}

.studio__save::selection {
  background-color: white;
}

.studio__clear {
  position: absolute;
  bottom: 10px;
  left: 15px;
  cursor: pointer;
  font-size: 0.8em;
  z-index: 1;
}

.studio__about {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px;
  cursor: pointer;
  z-index: 1;
  font-size: 0.85em;
}

.studio__leave {
  position: absolute;
  bottom: 10px;
  right: 7px;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
  padding-top: 100px;
}

.studio__leave--sideways {
  position: absolute;
  bottom: 132px;
  right: -37px;
  cursor: pointer;
  transform: rotate(+90deg);
  z-index: 1;
}

.icon {
  margin-right: 5px;
  margin-bottom: 4px;
}

.studio__peeking-mondrian-container {
  position: absolute;
  height: 100vh;
  top: 0px;
  right: 50px;
}

.studio__peeking-mondrian {
  height: 100%;
}

@media (max-width: 1000px) {
  .studio__peeking-mondrian {
    display: none;
  }

  .studio-container {
    width: 100vw;
  }
}

.studio__canvas-options {
  position: absolute;
  top: 10px;
  left: 15px;
  text-align: left;
  z-index: 1;
}

.name__hint {
  font-size: 0.5em;
}

.studio__title {
  font-size: 3em;
  position: absolute;
  float: left;
  transform: rotate(-90deg);
  top: 60vh;
  left: -60px;
}

.canvas__container {
  position: relative;
}

.canvas {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  box-shadow: 0 4px 12px -6px black;
  z-index: 2;
}

@media all and (orientation: portrait) {
  .links {
    visibility: hidden;
  }
  .canvas__container {
    width: 80vw;
    height: 80vw;
  }
  .studio > * .canvas__container {
    width: 95vw;
    height: 95vw;
  }
  .hall-of-fame-link {
    font-size: 0.7em;
  }
  .twitter-link {
    font-size: 0.7em;
  }

  .gallery__title {
    font-size: 2em;
    left: -10px;
  }

  .gallery__leave {
    font-size: 0.8em;
  }

  .gallery__continue--wall {
    font-size: 0.8em;
  }

  .thank-you {
    display: none !important;
  }
}
@media all and (orientation: landscape) {
  .canvas__container {
    width: 80vh;
    height: 80vh;
  }

  .canvas__container--gallery {
    width: 100vh;
    height: 70vh;
    background-color: white;
    margin: 0 auto;
  }
}

@media all and (orientation: portrait) {
  .canvas--square {
    width: 80vw;
    height: 80vw;
  }
  .studio > * .canvas--square {
    width: 95vw;
    height: 95vw;
  }
}
@media all and (orientation: landscape) {
  .canvas--square {
    width: 80vh;
    height: 80vh;
  }
  .canvas--square.canvas--gallery {
    width: 60vh;
    height: 60vh;
  }
}

@media all and (orientation: portrait) {
  .canvas--portrait {
    width: 60vw;
    height: 80vw;
  }
  .studio > * .canvas--portrait {
    width: 71.25vw;
    height: 95vw;
  }
}
@media all and (orientation: landscape) {
  .canvas--portrait {
    width: 60vh;
    height: 80vh;
  }
  .canvas--portrait.canvas--gallery {
    width: 45vh;
    height: 60vh;
  }
}

@media all and (orientation: portrait) {
  .canvas--landscape {
    width: 80vw;
    height: 60vw;
  }
  .studio > * .canvas--landscape {
    width: 95vw;
    height: 71.25vw;
  }
}
@media all and (orientation: landscape) {
  .canvas--landscape {
    width: 80vh;
    height: 60vh;
  }
  .canvas--landscape.canvas--gallery {
    width: 60vh;
    height: 45vh;
  }
}

.SplitPane {
  background-color: black;
}

.frame {
  border-image: url('/frame.jpg') 80 80 80 80 stretch stretch;
  border-style: inset;
  border-width: 40px;
  background-color: white;
}

.painting__block {
    width: 100%;
    height: 100%;
}

.painting__block--white {
    background-color: #eef0eb;
}

.painting__block--yellow {
    background-color: #f8cb04;
}


.painting__block--red {
    background-color: #b82015;
}


.painting__block--blue {
    background-color: #3652b7;
}

.painting__block--black {
    background-color: #1d1c25;
}

.popover--white {
    background-color: rgba(238, 240, 235, 0.5) !important;
}

.popover--yellow {
    background-color: rgba(248, 203, 4, 0.5) !important;
}


.popover--red {
    background-color: rgba(184, 32, 21, 0.5) !important;
}


.popover--blue {
    background-color: rgba(54, 82, 183, 0.5) !important;
}

.popover--black {
    background-color: rgba(29, 28, 37, 0.5) !important;

}
.popover--white > .popover {
    background-color: rgba(238, 240, 235, 0.5) !important;
}

.popover--yellow > .popover {
    background-color: rgba(248, 203, 4, 0.5) !important;
}


.popover--red > .popover {
    background-color: rgba(184, 32, 21, 0.5) !important;
}


.popover--blue > .popover {
    background-color: rgba(54, 82, 183, 0.5) !important;
}

.popover--black > .popover {
    background-color: rgba(29, 28, 37, 0.5) !important;
}

.popover--white > .popover > .popover-inner {
    background-color: rgba(238, 240, 235, 0.5) !important;
}

.popover--yellow > .popover > .popover-inner {
    background-color: rgba(248, 203, 4, 0.5) !important;
}


.popover--red > .popover > .popover-inner {
    background-color: rgba(184, 32, 21, 0.5) !important;
}


.popover--blue > .popover > .popover-inner {
    background-color: rgba(54, 82, 183, 0.5) !important;
}

.popover--black > .popover > .popover-inner {
    background-color: rgba(29, 28, 37, 0.5) !important;
}

.painting__block:hover > .painting__controls {
    opacity: 1;
}


.popover-body {
    font-size: 1.7em;
    padding: 0 2.5px !important;
}

.block__centre {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

/* .painting__controls {
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
} */

.painting__control {
    padding: 5px;
    color: white
}

.painting__control--white, .painting__control--yellow {
    color: #222127
}

.painting__control--split {
    font-size: 1.3em;
}

.painting__control--paint {
    margin-bottom: 4px;
}

.popover {
    top: -26px !important;
    border: none !important;
}

.arrow {
    display: none !important;
}

.Resizer {
    background: #000;
    z-index: 1;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
  }
   
  .Resizer:hover {
    transition: all 2s ease;
  }
   
  .Resizer.horizontal {
    height: 14px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
  }
   
  .Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
  }
   
  .Resizer.vertical {
    width: 14px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
  }
   
  .Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
  }
  .Resizer.disabled {
    cursor: default;
  }
  .Resizer.disabled:hover {
    border-color: transparent;
  }

  .studio__label__date {
      font-size: 0.8em;
  }
@media all and (orientation: portrait) {
  .gallery {
    position: absolute;
    left: 2.5%;
    top: 2.5vw;
  }

  .gallery__wall {
    height: 90vh;
  }

  .gallery__hanging {
    padding: 50px 10vw;
    background-color: white;
  }

  .gallery__continue {
    position: absolute;
    bottom: calc(40px);
    right: 10px;
    /* font-size: 0.8em; */
    cursor: e-resize;
  }

  .gallery__continue--wall {
    position: absolute;
    bottom: calc(60px);
    left: 100px;
    /* font-size: 0.8em; */
    cursor: e-resize;
  }
}

@media all and (orientation: landscape) {
  .gallery {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .gallery__wall {
    height: 100vh;
  }
  .gallery__hanging {
    margin: 10px 0px;
  }

  .gallery__continue {
    position: fixed;
    bottom: calc(40px);
    left: calc(100vw);
    transform: translate(-350px, 0);
    /* font-size: 0.8em; */
    cursor: e-resize;
  }

  .gallery__continue--wall {
    position: absolute;
    bottom: calc(40px);
    left: calc(100vw);
    transform: translate(-350px, 0);
    /* font-size: 0.8em; */
    cursor: e-resize;
  }
}

.gallery__label {
  width: 100%;
  margin: 0 auto;
  padding-top: 5px;
}

.gallery__input {
  border: none !important;
  border-radius: none !important;
  text-align: center !important;
  padding: 0px !important;
  height: 1.5em !important;
}

.form-control:focus {
  box-shadow: none !important;
  text-decoration: underline;
}

.gallery__input--artist {
  font-size: 1.3em !important;
}

.gallery__input--title {
  font-style: italic !important;
}

.gallery__canvas-button {
  margin: 0 10px !important;
  border: 1px solid #afafaf !important;
  border-radius: 0 !important;
  background-color: #eef0eb !important;
}

.gallery__square-button {
  width: 40px;
  height: 40px;
}

.gallery__portrait-button {
  width: 30px;
  height: 40px;
}

.gallery__landscape-button {
  width: 40px;
  margin-top: 5px !important;
  height: 30px;
}

.gallery__canvas-button--active {
  background-color: #afafaf !important;
}

.gallery__leave {
  position: fixed;
  bottom: 20px;
  left: 0px;
  padding: 0px 10px;
  cursor: pointer;
}

.gallery__hanging {
  display: inline-block;
}

.gallery__wall {
  overflow-x: scroll;
  white-space: nowrap;
  padding-left: 100px;
  position: relative;
}

.hanging_label {
  border: none !important;
  border-radius: none !important;
  text-align: center !important;
  padding: 0px !important;
  height: 1.5em !important;
}

.handing__artist {
  font-size: 1.3em !important;
}

.handing__title {
  font-style: italic !important;
}

.handing__year {
  font-size: 0.8em;
}

.handing__link {
  margin-top: 8px;
  font-size: 0.9em;
}

.handing__link > a {
  color: #3651b7;
}

.handing__twitter {
  color: #1da1f2;
  text-decoration: none;
  font-size: 0.7em;
}

.handing__twitter:hover {
  text-decoration: none;
}

.gallery__title {
  font-size: 3em;
  position: relative;
  float: left;
  transform: rotate(-90deg);
  top: 4em;
  left: -60px;
}

.gallery__backwards {
  position: fixed;
  bottom: calc(20px);
  left: 10px;
  /* font-size: 0.8em; */
  cursor: w-resize;
}

.gallery__continue:hover ~ .gallery__scroll {
  display: block;
}

.cover-up {
  position: absolute;
  bottom: calc(40px);
  right: calc(0px);
  /* font-size: 0.8em; */
  cursor: e-resize;
}

.hall-of-fame-link {
  position: absolute;
  top: 5px;
  left: 10px;
  color: goldenrod;
  z-index: 1;
}

.hall-of-fame-link:hover {
  color: goldenrod;
}

.twitter-link {
  opacity: 0.5;
  margin-left: 16px;
}

.twitter-link:hover {
  opacity: 1;
  text-decoration: none;
}

@media all and (orientation:landscape) { 
    .start__portrait {
        width: 40vw;
        position: absolute;
        bottom: 0;
        left: 25vw;
        z-index: 0
    }

    .start__painting {
        width: 25vw;
        position: absolute;
        top: 10vh;
        left: 5vw;
        z-index: 0
    }
 
    .start__title {
        font-size: 5em;
        z-index: 99999999;
        position: absolute;
        top: 20vh;
        right: 14vw;

    }

    .start__description {
        z-index: 99999999;
        position: absolute;
        top: calc(25vh + 6em);
        left: 57vw;
        width: 30vw !important;
        white-space: normal;
        text-align: left;
        padding: 5px 8px;
        background-color: rgba(255,255,255, 0.5);
    }
}

@media all and (orientation:portrait) { 
    .start__portrait {
        width: 70vw;
        position: absolute;
        bottom: 70px;
        left: 0px;
        z-index: 0
    }

    .start__painting {
        width: 30vw;
        position: absolute;
        bottom: 100px;
        right: 50px;
        z-index: 0
    }

    .start__title {
        font-size: 3em;
        z-index: 99999999;
        position: absolute;
        top: 20px;
        left: 20px;

    }
    

    .start__description {
        z-index: 99999999;
        position: absolute;
        top: calc(35px + 4em);
        left: 20px;
        width: 90%;
        white-space: normal;
        text-align: left;
        padding: 5px 0;
        background-color: rgba(255,255,255, 0.3);
    }
}

@media (max-width: 199px) {
    *{ font-size: 88%;}
  }
  
  @media (min-width: 200px) and  (max-width: 249px) {
    *{ font-size: 91%;  }
  }
  
  @media (min-width: 250px) and  (max-width: 299px) {
    *{ font-size: 94%;}
  }
  
  @media (min-width: 300px) and  (max-width: 349px) {
    *{ font-size: 97%;}
  }
  
  @media (min-width: 350px) {
    *{ font-size: 100%; }
  }
