.painting__block {
    width: 100%;
    height: 100%;
}

.painting__block--white {
    background-color: #eef0eb;
}

.painting__block--yellow {
    background-color: #f8cb04;
}


.painting__block--red {
    background-color: #b82015;
}


.painting__block--blue {
    background-color: #3652b7;
}

.painting__block--black {
    background-color: #1d1c25;
}

.popover--white {
    background-color: rgba(238, 240, 235, 0.5) !important;
}

.popover--yellow {
    background-color: rgba(248, 203, 4, 0.5) !important;
}


.popover--red {
    background-color: rgba(184, 32, 21, 0.5) !important;
}


.popover--blue {
    background-color: rgba(54, 82, 183, 0.5) !important;
}

.popover--black {
    background-color: rgba(29, 28, 37, 0.5) !important;

}
.popover--white > .popover {
    background-color: rgba(238, 240, 235, 0.5) !important;
}

.popover--yellow > .popover {
    background-color: rgba(248, 203, 4, 0.5) !important;
}


.popover--red > .popover {
    background-color: rgba(184, 32, 21, 0.5) !important;
}


.popover--blue > .popover {
    background-color: rgba(54, 82, 183, 0.5) !important;
}

.popover--black > .popover {
    background-color: rgba(29, 28, 37, 0.5) !important;
}

.popover--white > .popover > .popover-inner {
    background-color: rgba(238, 240, 235, 0.5) !important;
}

.popover--yellow > .popover > .popover-inner {
    background-color: rgba(248, 203, 4, 0.5) !important;
}


.popover--red > .popover > .popover-inner {
    background-color: rgba(184, 32, 21, 0.5) !important;
}


.popover--blue > .popover > .popover-inner {
    background-color: rgba(54, 82, 183, 0.5) !important;
}

.popover--black > .popover > .popover-inner {
    background-color: rgba(29, 28, 37, 0.5) !important;
}

.painting__block:hover > .painting__controls {
    opacity: 1;
}


.popover-body {
    font-size: 1.7em;
    padding: 0 2.5px !important;
}

.block__centre {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

/* .painting__controls {
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
} */

.painting__control {
    padding: 5px;
    color: white
}

.painting__control--white, .painting__control--yellow {
    color: #222127
}

.painting__control--split {
    font-size: 1.3em;
}

.painting__control--paint {
    margin-bottom: 4px;
}

.popover {
    top: -26px !important;
    border: none !important;
}

.arrow {
    display: none !important;
}

.Resizer {
    background: #000;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
  }
   
  .Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }
   
  .Resizer.horizontal {
    height: 14px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
  }
   
  .Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
  }
   
  .Resizer.vertical {
    width: 14px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
  }
   
  .Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
  }
  .Resizer.disabled {
    cursor: default;
  }
  .Resizer.disabled:hover {
    border-color: transparent;
  }

  .studio__label__date {
      font-size: 0.8em;
  }