.canvas__container {
  position: relative;
}

.canvas {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: black;
  box-shadow: 0 4px 12px -6px black;
  z-index: 2;
}

@media all and (orientation: portrait) {
  .links {
    visibility: hidden;
  }
  .canvas__container {
    width: 80vw;
    height: 80vw;
  }
  .studio > * .canvas__container {
    width: 95vw;
    height: 95vw;
  }
  .hall-of-fame-link {
    font-size: 0.7em;
  }
  .twitter-link {
    font-size: 0.7em;
  }

  .gallery__title {
    font-size: 2em;
    left: -10px;
  }

  .gallery__leave {
    font-size: 0.8em;
  }

  .gallery__continue--wall {
    font-size: 0.8em;
  }

  .thank-you {
    display: none !important;
  }
}
@media all and (orientation: landscape) {
  .canvas__container {
    width: 80vh;
    height: 80vh;
  }

  .canvas__container--gallery {
    width: 100vh;
    height: 70vh;
    background-color: white;
    margin: 0 auto;
  }
}

@media all and (orientation: portrait) {
  .canvas--square {
    width: 80vw;
    height: 80vw;
  }
  .studio > * .canvas--square {
    width: 95vw;
    height: 95vw;
  }
}
@media all and (orientation: landscape) {
  .canvas--square {
    width: 80vh;
    height: 80vh;
  }
  .canvas--square.canvas--gallery {
    width: 60vh;
    height: 60vh;
  }
}

@media all and (orientation: portrait) {
  .canvas--portrait {
    width: 60vw;
    height: 80vw;
  }
  .studio > * .canvas--portrait {
    width: 71.25vw;
    height: 95vw;
  }
}
@media all and (orientation: landscape) {
  .canvas--portrait {
    width: 60vh;
    height: 80vh;
  }
  .canvas--portrait.canvas--gallery {
    width: 45vh;
    height: 60vh;
  }
}

@media all and (orientation: portrait) {
  .canvas--landscape {
    width: 80vw;
    height: 60vw;
  }
  .studio > * .canvas--landscape {
    width: 95vw;
    height: 71.25vw;
  }
}
@media all and (orientation: landscape) {
  .canvas--landscape {
    width: 80vh;
    height: 60vh;
  }
  .canvas--landscape.canvas--gallery {
    width: 60vh;
    height: 45vh;
  }
}

.SplitPane {
  background-color: black;
}

.frame {
  border-image: url('/frame.jpg') 80 80 80 80 stretch stretch;
  border-style: inset;
  border-width: 40px;
  background-color: white;
}
