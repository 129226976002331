.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.awssld__content { 
  background-color: #ffffff !important;
  overflow-x: scroll !important;
  white-space: nowrap;
  justify-content: end !important;
}



.awssld__bullets {
  display: none !important;
}

.studio__container {
  position: relative;
  width: 70%;
  min-width: 100vh;
}

.awssld__content > div.gallery-page {
  overflow-x: scroll;
  /* position: absolute; */
}

.awssld__content {
  overflow-x: overlay !important;
}

.awssld__content > div {
  width: 100%;
}

div::-webkit-scrollbar {
  -webkit-appearance: none;
}
div::-webkit-scrollbar:horizontal {
  height: 11px;
}
div::-webkit-scrollbar-thumb {
  border: 2px solid white;
  background-color:#cecdcd;
}
div::-webkit-scrollbar-track { 
  background-color: transparent; 
  border-radius: 8px; 
} 